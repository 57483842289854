import React from 'react';

import Suits from './suits';

const Logo = () => (
	<div className='logo'>
		<h1>Top Up Poker</h1>
		<Suits></Suits>
		<div className='logo-bottom'>
			<span>L</span>
			<span>i</span>
			<span>v</span>
			<span>e</span>
			<span>&nbsp;</span>
			<span>P</span>
			<span>o</span>
			<span>k</span>
			<span>e</span>
			<span>r</span>
			<span>&nbsp;</span>
			<span>T</span>
			<span>r</span>
			<span>a</span>
			<span>i</span>
			<span>n</span>
			<span>i</span>
			<span>n</span>
			<span>g</span>
		</div>
	</div>
);

export default Logo;
