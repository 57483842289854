import React from 'react';

import { ImSpades, ImClubs, ImDiamonds, ImHeart } from 'react-icons/im';

const Suits = () => (
	<div className='logo-icons'>
		<ImDiamonds />
		<ImSpades />
		<ImHeart />
		<ImClubs />
	</div>
);

export default Suits;
