import React from 'react';
import { GrGatsbyjs } from 'react-icons/gr';

const Footer = () => (
	<footer className='footer-copyright'>
		<div>
			©{new Date().getFullYear()}, Built by{' '}
			<a
				href='https://jonathan-ho.dev'
				target='_blank'
				rel='noopener noreferrer'>
				JYH
			</a>{' '}
			with
			{` `}
			<a
				href='https://www.gatsbyjs.org'
				target='_blank'
				rel='noopener noreferrer'>
				<GrGatsbyjs className='footer-icon' />
			</a>
		</div>
	</footer>
);

export default Footer;
