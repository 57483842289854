import { Link } from 'gatsby';
import React from 'react';

import Logo from './logo';

const Header = () => {
	const activeStyle = {
		fontWeight: 'bold',
	};

	return (
		<header>
			<Link to='/'>
				<Logo />
			</Link>
			<div className='nav-bar-container'>
				<div className='nav-bar-links'>
					<Link to='/offerings' activeStyle={activeStyle}>
						Offerings
					</Link>
					<a
						href='https://topuppoker.substack.com/'
						target='_blank'
						rel='noopener noreferrer'>
						Substack
					</a>
					<Link to='/about' activeStyle={activeStyle}>
						About
					</Link>
					{/* <Link to='/students' activeStyle={activeStyle}>
						Student Portal
					</Link> */}
				</div>
			</div>
		</header>
	);
};

export default Header;
