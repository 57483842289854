import React from 'react';

import Header from './header';
import Footer from './footer';

import './layout.css';

const Layout = ({ children }) => (
	<div className='layout-container'>
		<Header />
		<div className='main-wrapper'>
			<main>{children}</main>
		</div>
		<Footer />
	</div>
);

export default Layout;
