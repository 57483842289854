import React from 'react';

import Layout from '../components/layout';
import Suits from '../components/suits';

const About = () => (
	<Layout>
		<h2>About the Instructor</h2>
		<p>
			Johnnie honed his game and built his poker bankroll in home and online
			games before transitioning to the live cash environment, where he's played
			semi-professionally since 2017. He currently plays no limit hold’em cash
			games at stakes up to $5/$10 in cardrooms and casinos in the NYC area and
			wherever his travels take him.
		</p>
		<br />
		<p>
			In addition to logging over 3,500 hours at the live poker tables, he has
			over a decade of experience as a classroom educator that informs his poker
			pedagogy.
		</p>
		<br />
		<Suits />
		{/* <br /> */}
		{/* <h3>Testimonials</h3> */}
	</Layout>
);

export default About;
